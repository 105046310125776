import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BsModalService } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';
import { ApiUrl } from 'src/app/common/core/apiUrl';
import { ResponseStatusCode } from 'src/app/common/core/responseStatusCode';
import { HttpService } from 'src/app/services/http.service';
import { LoaderService } from 'src/app/services/loader.service';
import { MessageService } from 'src/app/services/message.service';
import { UtilityService } from 'src/app/services/utility.service';
import { ResetPasswordComponent } from '../modal/reset-password/reset-password.component';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit, OnDestroy {

  public sidebarType: number = 0;
  public isShowReports: boolean = false;
  public isShowOrders: boolean = false;
  private routeSubscription: Subscription;

  constructor(
    private httpService: HttpService,
    private loaderService: LoaderService,
    public utilityService: UtilityService,
    private router: Router,
    private modalService: BsModalService,
    private messageService: MessageService
  ) { }

  ngOnInit(): void {
    this.checkSidebarType();
    this.routeSubscription = this.router.events.subscribe(() => {
      this.checkSidebarType();
    });
  }

  ngOnDestroy(): void {
    if (this.routeSubscription) {
      this.routeSubscription.unsubscribe();
    }
  }

  checkSidebarType = (): void => {
    if (this.router.url.includes('dashboard')) {
      this.sidebarType = 1;
      if (this.router.url.includes('dashboard/new-order')) {
        this.sidebarType = 2;
      } else if (this.router.url.includes('dashboard/notifications')) {
        this.sidebarType = 3;
      } else if (this.router.url.includes('dashboard/payment')) {
        this.sidebarType = 4;
      } else if (this.router.url.includes('dashboard/select-vehicles')) {
        this.sidebarType = 5;
      }
    } else if (this.router.url.includes('delivery-locations')) {
      this.sidebarType = 11;
      if (this.router.url.includes('delivery-locations/add-new-location')) {
        this.sidebarType = 12;
      } else if (this.router.url.includes('delivery-locations/edit-location')) {
        this.sidebarType = 13;
      }
    } else if (this.router.url.includes('manage-vehicles')) {
      this.sidebarType = 21;
      if (this.router.url.includes('manage-vehicles/add-new-vehicle')) {
        this.sidebarType = 22;
      } else if (this.router.url.includes('manage-vehicles/edit-vehicle')) {
        this.sidebarType = 23;
      }
    } else if (this.router.url.includes('automatic-orders')) {
      this.sidebarType = 41;
      if (this.router.url.includes('automatic-orders/edit-automatic-order')) {
        this.sidebarType = 42;
      }
    } else if (this.router.url.includes('orders')) {
      this.sidebarType = 31;
      if (this.router.url.includes('orders/edit-order')) {
        this.sidebarType = 32;
      } else if (this.router.url.includes('orders/track-order')) {
        this.sidebarType = 33;
      }
    } else if (this.router.url.includes('payments')) {
      this.sidebarType = 51;
    } else if (this.router.url.includes('detailed-reports')) {
      this.sidebarType = 71;
    } else if (this.router.url.includes('custom-reports')) {
      this.sidebarType = 91;
    } else if (this.router.url.includes('snapshot-reports')) {
      this.sidebarType = 61;
    } else if (this.router.url.includes('profile')) {
      this.sidebarType = 81;
    } else if (this.router.url.includes('telematics')) {
      this.sidebarType = 101;
    } else if (this.router.url.includes('wallet')) {
      this.sidebarType = 111;
    }
  }

  navigate = (routeLink: string): void => {
    if (routeLink) {
      this.router.navigate([routeLink]);
    }
  }

  resetPassword = (): void => {
    const modalRef = this.modalService.show(ResetPasswordComponent, {
      backdrop: 'static',
      keyboard: false,
      class: 'custom-modal-content-radius modal-dialog-centered',
      // initialState: { data: this.data }
    });
  }

  signOut = (): void => {
    const reference = this;
    this.messageService.alertCallbackWithTwoButton('warning', 'Do you want to sign out?', '',
      function (data: boolean) {
        if (data) {
          //localStorage.clear();
          // localStorage.removeItem('accessToken');
          // reference.router.navigate(['login']);
          reference.logout();
        }
      });
  }

  logout = (): void => {
    const reference = this;
    this.loaderService.showLoader();
    this.httpService.postData(ApiUrl.LOGOUT).subscribe((response) => {
      this.loaderService.hideLoader();
      if (response.statusCode == ResponseStatusCode.OK) {
        localStorage.removeItem('accessToken');
        reference.router.navigate(['login']);
      }
    }, (error) => {
      this.loaderService.hideLoader();
      this.utilityService.unAuthorized(error);
    });
  }

  onClickCollapse = (type: string): void => {
    switch (type) {
      case 'REPORTS':
        this.isShowReports = !this.isShowReports;
        this.isShowOrders = false;
        break;
      case 'ORDERS':
        this.isShowReports = false;
        this.isShowOrders = !this.isShowOrders;;
        break;
      default:
        break;
    }
  }

}
