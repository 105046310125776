export const ApiUrl = {
    LOGIN: 'login',// using
    BOOKING_REPORT: 'bookingReport',// using
    FUEL_CONSUMPTION_REPORT: 'fuelConsumptionReport',// using
    UPLOAD_IMAGE: 'uploadImage',// using
    LOGIN_BASIC_USER: 'loginbasicusers',
    SEND_OTP: 'sendOtp',
    VERIFY_OTP: 'verifyOTP',// using
    FORGOT_PASSWORD: 'forgotPassword',
    V2_FORGOT_PASSWORD: 'v2/forgot-password',// using
    ADD_EDIT_VEHICLE: 'addEditVehicle',// using
    DELETE_VEHICLE: 'deleteVehicle',// using
    DELETE_BASIC_USER: 'deletebasicuser',
    GENERATE_QR: 'generateQr',// using
    PURCHASE_MEMBERSHIP: 'purchaseMembership',
    RENEWAL: 'renewal',
    TIME_SLOTS: 'timeSlots',
    SIGN_UP: 'signUp',// using
    UPDATE_PROFILE: 'updateProfile',// using
    BOOKING: 'booking',// using
    ADD_BASIC_USERS: 'addbasicusers',
    BOOKING_V2: 'booking/v2',// using
    GET_BOOKING: 'getBooking',// using
    CANCEL_BOOKING: 'cancelBooking',// using
    RATE_DRIVER: 'rateDriver',// using
    ADD_CARD: 'addCard',// using
    DELETE_CARD: 'deleteCard',// using
    BANK_ADD: 'bank/add',// using
    BANK_DELETE: 'bank/delete',// using
    MAKE_DEFAULT: 'makeDefault',// using
    BANK_DEFAULT: 'bank/default',// using
    GET_NOTIFICATIONS: 'getNotifications',// using
    GET_DATA: 'getData',// using
    GET_PROFILE: 'getProfile',// using
    GET_PAYMENT_DETAILS: 'getPaymentDetails',
    CHANGE_PASSWORD: 'changePassword',// using
    CHECK_PROMO: 'checkPromo',// using
    GET_VEHICLE: 'getVehicle',// using
    GET_NEW_VEHICLE: 'getNewVehicle',
    VEHICLE_VERSIONS: 'vehicle/versions',// using
    CURRENT_BOOKING: 'currentBooking',// using
    RESEND_OTP: 'resendOTP',// using
    GET_COUNTRY_CITY: 'getCountryCity',// using
    CREATE_DIGITAL_ID: 'createDigitalId',
    PAYMENT_ACKNOWLEDGEMENT: 'paymentAcknowlodgement',
    CHECKOUT_DIGITAL_SESSION: 'checkoutDigitalSession',
    ADD_EDIT_RECUR_VEHICLE: 'addEditRecurVehicles',// using
    GET_AREA_FROM_ZIPCODE: 'getAreaFromZipcode',// using
    GET_DASHBOARD_GRAPH_DATA: 'getDashboardGraphData',// using
    BOOKING_RECURRING: 'booking/recurring',// using
    CANCEL_RECURRING: 'cancel/recurring',// using
    DOWNLOAD_INVOICE: 'downloadInvoice',// using
    GET_DASHBOARD_DATA_V2: 'getDashboardDataV2',// using
    DOWNLOAD_SAMPLE: 'downloadSample',// using
    MANAGED_LOCATIONS: 'managedLocations',// using
    V2_MULTI_DELETE_VEHICLE: 'v2/multi-delete-vehicle',// using
    POST_GET_DATA_OF_CSV: 'getDataOfCsv',// using
    MANAGE_LOCATIONS: 'manageLocations',// using
    V2_CHECK_SERVICE_AVAILABILITY_IN_AREA: 'v2/checkServiceAvailabilityInArea',// using
    LIST_POLYGONS: 'listPolygons',// using
    CHECK_FUEL_EXISTENCE_WITH_ZIP: 'checkFuelExistenceWithZip',// using
    GET_FUEL_PRICING_AND_FILLUP_DAYS: 'getFuelPricingAndFillUpDays',// using
    EDIT_BOOKING: 'EditBooking',// using
    GET_CUSTOM_CUSTOMER_REPORTS: 'getCustomCustomerReports',// using
    GET_CUSTOMER_REPORTS_V2: 'getCustomerReportsV2',// using
    GET_USA_COUNTY_AND_STATES: 'getUsaCountyAndStates',// using
    V2_VERIFY_OTP: 'v2/verifyOTP',// using
    LOGIN_BY_ACCESS_TOKEN: 'loginByAccessToken',// using
    GET_COUNTY_FROM_ZIP_CODE: 'getcoutyFromZipcode',//using
    LOGOUT: 'logout',//using
    REMOVE_TEAM_MEMBER: 'removeTeamMember',//using
    NOTIFY_PENDING_PAYMENT: 'notifyPendingPayment',//using
};
