<div class="edit-modal">
    <div class="modal-body">

        <div class="header-modal">
            <div class="icon-side">Cancel Order</div>
            <div class="cross-side">
                <a href="javascript:void(0)" class="btn-close" (click)="closeModal()">
                    <img src="./../../../../assets/images/login-flow-images/Group 791063.png">
                </a>
            </div>
        </div>

        <div class="p-20 popup-input ">
            <form>
                <h2 class="delet-card">Are you sure you want to <br>
                    Cancel the order?</h2>
                <div class="row">
                    <div class="col-md-6"> <button type="button" class="btn btn-default update-btn"
                            (click)="closeModal()">No, Keep
                            it</button></div>
                    <div class="col-md-6"> <button type="button" class="btn btn-default  yes-btn" (click)="closeModalWithYes()">Yes</button>
                    </div>
                </div>
            </form>
        </div>

    </div>
</div>