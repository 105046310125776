export const BaseApiUrl = {
    // dev server 
    // URL: "https://apidev.gasitup.com",
    // URL_USER: "https://apidev.gasitup.com/user/",
    // URL_COMMON: "https://apidev.gasitup.com/common/",

    // live server 
    URL: "https://api.gasitup.com",
    URL_USER: "https://api.gasitup.com/user/",
    URL_COMMON: "https://api.gasitup.com/common/",

    // URL_USER: "https://test-api.gasitup.com/user/", //test server
    // URL_USER: "https://api.gasitup.com/user/", //live server
    // URL_USER: "http://192.168.102.13:8081/user/"  // local server
    // URL_USER: "https://apitest.gasitup.com/user/", //test server

    // common
    URL_CARSXE_IMAGES: "http://api.carsxe.com/images", // https://api.carsxe.com/docs
    URL_CARSXE_KEY: "4594qov31_8z4bz2ub1_p76hxv6sz", // https://api.carsxe.com/docs
};