<div class="d-flex justify-content-between">

    <h1 *ngIf="headerType == 1"><i class="me-2"><img src="./../../../assets/images/login-flow-images/dashboard.svg"
                alt=""></i><span>GIU Fuel Management</span></h1>
    <h1 *ngIf="headerType == 2"><span>
            <i class="me-2 back-button cursor-pointer" (click)="navigate('/dashboard')"><img
                    src="./../../../assets/images/login-flow-images/Back.svg" alt="" width="30px" class="" /></i>
            Place New Order</span></h1>
    <h1 *ngIf="headerType == 3"></h1>
    <h1 *ngIf="headerType == 4"><span>Payment</span></h1>
    <div class="main-title" *ngIf="headerType == 5 || headerType == 32 || headerType == 42">
        <h1>Select Vehicle(s)</h1>
        <p class="text-14 text-white">Choose the vehicle(s) from your list that need fueling.</p>
    </div>

    <!--Group (7).png-->
    <h1 *ngIf="headerType == 11"><span>
        <!-- <img src="./../../../assets/images/login-flow-images/delivery-location-maker-white.svg" alt="" class="pe-2"> -->
        <img src="./../../../assets/images/White GIU pin.svg" alt="" class="pe-2">
            Delivery
            Locations</span></h1>
    <h1 *ngIf="headerType == 12">

        <span class="d-flex align-items-center">
            <i class="me-2 back-button cursor-pointer" (click)="navigate('/delivery-locations')"><img
                    src="./../../../assets/images/login-flow-images/Back.svg" alt="" width="30px" class="" /></i>
            <!-- <img src="./../../../assets/images/login-flow-images/delivery-location-maker-white.svg" alt="" class="pe-2"> -->
            Add
            Location</span>
    </h1>

    <h1 *ngIf="headerType == 13"><span>
            <i class="me-2 back-button cursor-pointer" (click)="navigate('/delivery-locations')"><img
                    src="./../../../assets/images/login-flow-images/Back.svg" alt="" width="30px" class="" /></i>
            <!-- <img src="./../../../assets/images/login-flow-images/delivery-location-maker-white.svg" alt="" class="pe-2"> -->
            Edit
            Location</span></h1>

    <h1 *ngIf="headerType == 21"> <i class="left-icon"><img src="./../../../assets/images/login-flow-images/car.png"
                alt=""></i> Manage Vehicles</h1>
    <h1 *ngIf="headerType == 22">
        <i class="me-2 back-button cursor-pointer" (click)="onClickBack()"><img
                src="./../../../assets/images/login-flow-images/Back.svg" alt="" width="30px" class="" /></i>
        <!-- <i class="left-icon"><img src="./../../../assets/images/login-flow-images/car.png" alt=""></i>  -->
        Add Vehicle
    </h1>
    <h1 *ngIf="headerType == 23">
        <i class="me-2 back-button cursor-pointer" (click)="navigate('/manage-vehicles')"><img
                src="./../../../assets/images/login-flow-images/Back.svg" alt="" width="30px" class="" /></i>
        <!-- <i class="left-icon"><img src="./../../../assets/images/login-flow-images/car.png" alt=""></i> -->
         Edit Vehicle
    </h1>

    <h1 *ngIf="headerType == 31"> <i class="left-icon"><img src="./../../../assets/images/login-flow-images/petrol.svg"
                alt=""></i> Order Activity</h1>

    <h1 *ngIf="headerType == 33"> <i class="left-icon"><img src="./../../../assets/images/login-flow-images/petrol.svg"
                alt=""></i> Track Order</h1>

    <h1 *ngIf="headerType == 41"> <i class="left-icon"><img src="./../../../assets/images/login-flow-images/loop.svg"
                alt=""></i> Automatic Orders</h1>

    <h1 *ngIf="headerType == 51"><i class="left-icon me-2"><img
                src="./../../../assets/images/login-flow-images/payment.svg" alt=""></i> Payments</h1>

    <h1 *ngIf="headerType == 61"> <i class="left-icon me-1"><img
                src="./../../../assets/images/login-flow-images/edit.svg" alt=""></i> Snapshot Reports</h1>

    <h1 *ngIf="headerType == 71"> <i class="left-icon me-1"><img
                src="./../../../assets/images/login-flow-images/edit.svg" alt=""></i>Detailed Reports</h1>

    <h1 *ngIf="headerType == 91"> <i class="left-icon me-1"><img
                src="./../../../assets/images/login-flow-images/edit.svg" alt=""></i>Custom Reports</h1>

    <h1 *ngIf="headerType == 101"> <i class="left-icon me-1"><img
                src="./../../../assets/images/login-flow-images/telematics-icon.svg" alt=""></i>Telematics</h1>

    <h1 *ngIf="headerType == 81"> <i class="left-icon me-1"><img
                src="./../../..//assets/images/login-flow-images/admin.svg" alt="" class="pe-2"></i> Profile</h1>

    <h1 *ngIf="headerType == 111"><i class="left-icon me-2"><img
                src="./../../../assets/images/login-flow-images/payment.svg" alt=""></i> Wallet</h1>

    <ul class="main-links d-flex align-items-center justify-content-end">
        <li *ngIf="headerType == 1 || headerType == 91">
            <!-- <div class="toggle-button-cover">
                <div class="button-cover">
                  <div class="button r" id="button-1">
                    <input type="checkbox" class="checkbox">
                    <div class="knobs"></div>
                    <div class="layer"></div>
                  </div>
                </div>
              </div> -->
            <a class="refresh-btn text-white" href="javascript:void(0)" (click)="refreshGraphDetails()">
                <i class=""><img src="./../../../assets/images/login-flow-images/refresh.svg" /></i>
            </a>
        </li>
        <li *ngIf="headerType == 1"><a class="white-btn text-center text-capitalize" href="javascript:void(0)"
                (click)="navigate('/dashboard/new-order')">
                <i class="fa-solid fa-plus me-2"></i><span>New Order</span></a></li>

        <li *ngIf="headerType == 11"><a class="white-btn text-center text-capitalize border-round"
                href="javascript:void(0)" (click)="navigate('/delivery-locations/add-new-location')"><i
                    class="fa-solid fa-plus me-2"></i><span>Add New Location</span></a></li>

        <li *ngIf="headerType == 21">
            <a class="white-btn text-center text-capitalize border-round" href="javascript:void(0)"
                (click)="uploadVehicleInfo()"><i class="icon-14 me-2"><img
                        src="./../../../assets/images/login-flow-images/upload.png" alt=""></i><span>Upload Vehicle
                    Info</span></a>
        </li>

        <li *ngIf="headerType == 21">
            <a class="white-btn text-center text-capitalize border-round" href="javascript:void(0)"
                (click)="navigate('/manage-vehicles/add-new-vehicle')"><i class="fa-solid fa-plus me-2"></i><span>Add
                    New Vehicle</span></a>
        </li>
        <li *ngIf="headerType == 5">
            <a class="white-btn text-center text-capitalize border-round" href="javascript:void(0)"
                (click)="navigate('/manage-vehicles/add-new-vehicle/location/'+locationId)"><i
                    class="fa-solid fa-plus me-2"></i><span>Add
                    New Vehicle</span></a>
        </li>


        <li class="notification-btn position-relative" *ngIf="headerType != 3"><a class="round-icon"
                href="javascript:void(0)"><i><img src="./../../../assets/images/login-flow-images/bell.svg"
                        alt=""></i></a>
            <div class="notification-list">
                <h6>Notifications</h6>
                <ul class="notify-text">
                    <li class="mb-3" *ngFor="let data of notificationList; let i = index">
                        <a href="javascript:void(0)"><i class="round-icon"><img
                                    src="./../../../assets/images/login-flow-images/bell.svg" alt=""></i>
                            <p class="mb-2">{{data?.driverId?.firstName}} {{data?.driverId?.lastName}} {{data?.text}}
                            </p>
                            <p>{{data?.createdOn | date:'MMM d, y, h:mm a'}}</p>
                        </a>
                    </li>
                </ul>
                <a class="w-btn text-center d-block mt-3" href="javascript:void(0)"
                    (click)="navigate('/dashboard/notifications')">View More..</a>
            </div>
        </li>
        <li><a class="round-icon" href="javascript:void(0)" (click)="navigate('/profile')"><i>
                    <!--  ./../../../assets/images/login-flow-images/user.png -->
                    <img [src]="profileDetails?.imageUrl?.original" alt="" class="rounded-circle h-100">
                </i></a></li>
    </ul>
</div>