<div class="edit-modal">
    <div class="modal-body">

        <div class="header-modal">
            <div class="icon-side"><img src="./../../../../assets/images/login-flow-images/fe0W2I.tif_1_.png"
                    class="pe-2">
                Order Details</div>
            <div class="cross-side"><a href="javascript:void(0)" class="btn-close" (click)="closeModal()"><img
                        src="./../../../../assets/images/login-flow-images/Group 791063.png"></a></div>
        </div>

        <div class="p-20 popup-input popup-input-date p-l-30" *ngIf="address">
            <h5 class="locatinn-h">Location</h5>
            <p class="locatinn-p">{{address}}</p>
        </div>

        <div class="table-responsive popup-table table-height">
            <table class="table mb-0">
                <thead class="table-dark table-light">
                    <tr>
                        <th class="p-l-30">Plate Number </th>
                        <th>Vehicle Details </th>
                        <th>Fuel Type </th>
                        <th>Price Per Gallon </th>
                        <th>Gallons </th>
                        <th>Price </th>
                        <th>status</th>
                    </tr>
                </thead>
                <tbody class="table-td">
                    <tr *ngFor="let vehicle of  vehicleList; let i = index">
                        <td class="p-l-30">{{vehicle.vehicleId?.licensePlate || vehicle.vehicleId[0]?.licensePlate ||
                            '-'}}</td>
                        <td>
                            {{vehicle?.vehicleId?.make || vehicle?.vehicleId[0]?.make}} -
                            {{vehicle?.vehicleId?.model || vehicle?.vehicleId[0]?.model}}
                        </td>
                        <td>{{vehicle?.gasId?.name}} {{vehicle?.gasId?.grade ? vehicle?.gasId?.grade:''}}</td>
                        <td>${{utilityService.numberWithCommas(getToFixed(vehicle?.gasPrice || 0))}}</td>
                        <td>{{getToFixed(vehicle?.quantity || 0)}}
                            <!-- <p class="vehicle-charge">Vehicle Not Found Charges </p> -->
                        </td>
                        <!--${{vehicle.amountTotal | number:2}}-->
                        <td>${{utilityService.numberWithCommas(getToFixed(vehicle?.amountTotal || 0))}} </td>
                        <td>
                            <span *ngIf="vehicle.bookingStatus == 0">Pending</span>
                            <span *ngIf="vehicle.bookingStatus == 6">Vehicle Not Found</span>
                            <span *ngIf="vehicle.bookingStatus == 8">Canceled
                                <span *ngIf="vehicle.cancelReason">- {{vehicle.cancelReason}}</span>
                            </span>
                            <span *ngIf="vehicle.bookingStatus == 15">Inprogress</span>
                            <span *ngIf="vehicle.bookingStatus == 16 && !!vehicle.quantity">Filled</span>
                            <span *ngIf="vehicle.bookingStatus == 16 && !vehicle.quantity">No Service</span>
                        </td>
                    </tr>
                    <tr *ngIf="!vehicleList?.length">
                        <td colspan="7" class="no-found">
                            <span>No vehicles found</span>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>

    </div>
</div>