import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SidebarComponent } from './sidebar/sidebar.component';
import { HeaderComponent } from './header/header.component';
import { ResetPasswordComponent } from './modal/reset-password/reset-password.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CancelOrderComponent } from './modal/cancel-order/cancel-order.component';
import { RecurringOrderDetailsComponent } from './modal/recurring-order-details/recurring-order-details.component';
import { PaginationComponent } from './pagination/pagination.component';
import { OrderDetailsComponent } from './modal/order-details/order-details.component';
import { DeleteComponent } from './modal/delete/delete.component';
import { VehicleDetailsComponent } from './modal/vehicle-details/vehicle-details.component';
import { UploadVehicleInfoComponent } from './modal/upload-vehicle-info/upload-vehicle-info.component';
import { AddNewCardComponent } from './modal/add-new-card/add-new-card.component';
import { AddNewBankComponent } from './modal/add-new-bank/add-new-bank.component';
import { UptoTwoDigitDecimalNumberDirective } from './directives/upto-two-digit-decimal-number.directive';
import { OnlyNumberDirective } from './directives/only-number.directive';
import { ScheduleFuelDeliveryComponent } from './modal/schedule-fuel-delivery/schedule-fuel-delivery.component';
import { RecurringFuelDeliveryComponent } from './modal/recurring-fuel-delivery/recurring-fuel-delivery.component';
import { RequestNowComponent } from './modal/request-now/request-now.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { DashboardDateRangeComponent } from './modal/dashboard-date-range/dashboard-date-range.component';
import { NotificationDriverRatingComponent } from './modal/notification-driver-rating/notification-driver-rating.component';
import { LocationComponent } from './location/location.component';
import { GooglePlaceDirective } from './directives/google-place.directive';



@NgModule({
  declarations: [
    SidebarComponent,
    HeaderComponent,
    ResetPasswordComponent,
    CancelOrderComponent,
    RecurringOrderDetailsComponent,
    PaginationComponent,
    OrderDetailsComponent,
    DeleteComponent,
    VehicleDetailsComponent,
    UploadVehicleInfoComponent,
    AddNewCardComponent,
    AddNewBankComponent,
    UptoTwoDigitDecimalNumberDirective,
    OnlyNumberDirective,
    ScheduleFuelDeliveryComponent,
    RecurringFuelDeliveryComponent,
    RequestNowComponent,
    DashboardDateRangeComponent,
    NotificationDriverRatingComponent,
    LocationComponent,
    GooglePlaceDirective
  ],
  exports: [
    SidebarComponent,
    HeaderComponent,
    ResetPasswordComponent,
    CancelOrderComponent,
    RecurringOrderDetailsComponent,
    PaginationComponent,
    OrderDetailsComponent,
    DeleteComponent,
    VehicleDetailsComponent,
    UploadVehicleInfoComponent,
    AddNewCardComponent,
    AddNewBankComponent,
    UptoTwoDigitDecimalNumberDirective,
    OnlyNumberDirective,
    ScheduleFuelDeliveryComponent,
    RecurringFuelDeliveryComponent,
    RequestNowComponent,
    DashboardDateRangeComponent,
    NotificationDriverRatingComponent,
    NgxPaginationModule,
    LocationComponent,
    GooglePlaceDirective
    //CommonModule,
    // FormsModule,
    //ReactiveFormsModule
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgxPaginationModule
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class SharedModule { }
