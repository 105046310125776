import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { ApiUrl } from 'src/app/common/core/apiUrl';
import { ResponseStatusCode } from 'src/app/common/core/responseStatusCode';
import { HttpService } from 'src/app/services/http.service';
import { LoaderService } from 'src/app/services/loader.service';
import { MessageService } from 'src/app/services/message.service';

@Component({
  selector: 'app-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.css']
})
export class SignUpComponent implements OnInit, OnDestroy {

  signUpForm: FormGroup = new FormGroup({
    email: new FormControl('', [Validators.email, Validators.required]),
    companyName: new FormControl('', [Validators.required]),
    phoneNumber: new FormControl('', [Validators.required]),
    countryCode: new FormControl('+1'),
    userType: new FormControl(2),
    referalCode: new FormControl(''),
    password: new FormControl('', [Validators.required, Validators.minLength(6)]) // Password length must be at least 6 characters long.
  });
  signUpFormSubscription: Subscription;
  showError: boolean = false;
  public passwordType: string = 'password';
  public passwordTypeClass: string = 'fa fa-fw fa-eye field-icon toggle-password fa-eye fa-eye-slash';


  constructor(
    private httpService: HttpService,
    private router: Router,
    private loaderService: LoaderService,
    private messageService: MessageService
  ) { }

  ngOnInit(): void {
    this.signUpFormSubscription = this.signUpForm.valueChanges.subscribe((value) => {
      this.showError = false;
    });
  }

  signUp = (): void => {
    if (this.signUpForm.valid) {
      this.loaderService.showLoader();
      const postData: any = this.signUpForm.getRawValue();
      if (postData.referalCode == '') {
        delete postData.referalCode;
      }
      postData['isNewPanelSignUp'] = true;
      this.httpService.postData(ApiUrl.SIGN_UP, postData).subscribe((response) => {
        this.loaderService.hideLoader();
        if (response.statusCode == ResponseStatusCode.OK) {
          const reference = this;
          this.messageService.alertCallback('success', response.message, '', function () {
            reference.router.navigate(['/verification-code', {
              userType: response.data.userType,
              phoneNumber: response.data.phoneNumber,
              email: response.data.email,
              countryCode: response.data.countryCode,
              companyName: response.data.companyName
            }]);
          });
        }
      }, (error) => {
        this.loaderService.hideLoader();
      });
    } else {
      this.showError = true;
    }
  }

  ngOnDestroy(): void {
    if (this.signUpFormSubscription) {
      this.signUpFormSubscription.unsubscribe();
    }
  }

  changePasswordType = (): void => {
    if (this.passwordType == 'password') {
      this.passwordType = 'text';
      this.passwordTypeClass = 'fa fa-fw fa-eye field-icon toggle-password';
    } else {
      this.passwordType = 'password';
      this.passwordTypeClass = 'fa fa-fw fa-eye field-icon toggle-password fa-eye fa-eye-slash';
    }

  }


}
