import { BrowserModule } from '@angular/platform-browser';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './public-pages/login/login.component';
import { SignUpComponent } from './public-pages/sign-up/sign-up.component';
import { PageNotFoundComponent } from './public-pages/page-not-found/page-not-found.component';
import { HttpService } from './services/http.service';
import { ForgotPasswordComponent } from './public-pages/forgot-password/forgot-password.component';
import { VerificationCodeComponent } from './public-pages/verification-code/verification-code.component';
import { ProfileSetupComponent } from './public-pages/profile-setup/profile-setup.component';
import { AccountSetupComponent } from './public-pages/account-setup/account-setup.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MaterialModule } from './shared/material/material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { MessageService } from './services/message.service';
import { LoaderService } from './services/loader.service';
import { LoaderComponent } from './public-pages/loader/loader.component';
import { WelcomeComponent } from './public-pages/welcome/welcome.component';
import { BsModalService, ModalModule } from 'ngx-bootstrap/modal';
import { CommonModule } from '@angular/common';
import { UtilityService } from './services/utility.service';
import { CookieService } from 'ngx-cookie-service';
import { SocketIOService } from './services/socket-i-o.service';
import { CalculatorComponent } from './public-pages/calculator/calculator.component';
import { CalculatorOnlyNumberDirective } from './public-pages/calculator/directives/calculator-only-number.directive';
import { TwoStepVerificationComponent } from './public-pages/two-step-verification/two-step-verification.component';
import { VerifyOTPComponent } from './public-pages/verify-otp/verify-otp.component';
import { AccessUserComponent } from './public-pages/access-user/access-user.component';
import { SharedModule } from './shared/shared.module';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    SignUpComponent,
    PageNotFoundComponent,
    ForgotPasswordComponent,
    VerificationCodeComponent,
    ProfileSetupComponent,
    AccountSetupComponent,
    LoaderComponent,
    WelcomeComponent,
    CalculatorComponent,
    CalculatorOnlyNumberDirective,
    TwoStepVerificationComponent,
    VerifyOTPComponent,
    AccessUserComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MaterialModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    ModalModule.forRoot(),
    SharedModule
  ],
  providers: [
    CookieService,
    HttpService,
    MessageService,
    LoaderService,
    BsModalService,
    UtilityService,
    // SocketIOService
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [AppComponent]
})
export class AppModule { }
