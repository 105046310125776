import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { HttpService } from 'src/app/services/http.service';
import { LoaderService } from 'src/app/services/loader.service';
import { MessageService } from 'src/app/services/message.service';
import { UtilityService } from 'src/app/services/utility.service';

declare let mapboxgl: any;
declare let MapboxDraw: any;
declare let MapboxGeocoder: any;

@Component({
  selector: 'app-location',
  templateUrl: './location.component.html',
  styleUrls: ['./location.component.css']
})
export class LocationComponent implements OnInit {

  @Input() locationList: any[];
  @Input() isDashboard: boolean = false;
  @Output() markerClick: EventEmitter<any> = new EventEmitter<any>();
  private map: any;
  private markerList: any[] = [];
  public layerIsStreet: boolean = true;
  public addressText: any = '';

  constructor(
    private httpService: HttpService,
    private loaderService: LoaderService,
    private messageService: MessageService,
    private utilityService: UtilityService
  ) { }

  ngOnInit(): void {
    this.initializeMap();
  }

  initializeMap = (): void => {
    const reference = this;
    const locationcontainer = document.getElementById("locationContainer") as HTMLElement;
    mapboxgl.accessToken = 'pk.eyJ1IjoiZ2FzaXR1cCIsImEiOiJjanc5ZnJ5MjMyenRkNDNwZ3RzZWVtYXV6In0.Evr6spGJNZ4Tg2N-5XViiw';

    let long = -100.024872;
    let lat = 37.753098;
    if ((reference.locationList || []).length) {
      long = (reference.isDashboard ? reference.locationList[0].location[0] : reference.locationList[0].long);
      lat = (reference.isDashboard ? reference.locationList[0].location[1] : reference.locationList[0].lat);
    }

    this.map = new mapboxgl.Map({
      container: locationcontainer,
      zoom: 15,//3,
      // center: [-68.13734351262877, 45.137451890638886],
      // center: [-100.024872, 37.753098],
      // center: [(reference.isDashboard ? reference.locationList[0].location[0] : reference.locationList[0].long),
      // (reference.isDashboard ? reference.locationList[0].location[1] : reference.locationList[0].lat)],
      center: [long, lat],
      style: 'mapbox://styles/mapbox/streets-v11',
      minZoom: 3,
    });

    // this.map.addControl(new MapboxGeocoder({
    //   accessToken: mapboxgl.accessToken,
    //   mapboxgl: mapboxgl,
    //   placeholder: 'Google map search location'
    // }));
    if (!this.isDashboard) {
      this.map.addControl(new mapboxgl.FullscreenControl({ container: document.querySelector('body') }));
    }
    this.map.addControl(new mapboxgl.NavigationControl());

    this.map.on('load', () => {
      reference.createAllMarker();
    });

  }

  switchLayer = (layer: string): void => {
    this.layerIsStreet = !this.layerIsStreet;
    this.map.setStyle(`mapbox://styles/mapbox/${layer}`);
  }

  createAllMarker = (): void => {
    const reference = this;
    reference.locationList.forEach((item, index) => {
      reference.createMarker(item, index);
    });
  }

  createMarker = (data: any, index: number): void => {
    const reference = this;
    if (reference.markerList.length && reference.markerList[index]) {
      reference.markerList[index].remove();
    }

    reference.markerList[index] = new mapboxgl.Marker({ draggable: false })
      .setLngLat([(reference.isDashboard ? data.location[0] : data.long), (reference.isDashboard ? data.location[1] : data.lat)])
      .setPopup(
        new mapboxgl.Popup({ offset: 25 })
          .setHTML(
            `<h3>${data.locationName}</h3><p>${data.address}</p>`
          )
      )
      .addTo(reference.map);

    reference.markerList[index].getElement().addEventListener('click', function () {
      reference.onClickMarker(data, index);
    });

  }

  onClickMarker = (data: any, index: number): void => {
    this.markerClick.emit(data);
  }

  address = (data: any): void => {
    // this.locationDetails.lat = (data || {}).lat;
    // this.locationDetails.long = (data || {}).lng;
    // this.locationDetails.address = (data || {}).formatted_address_second || '';// (data || {}).formatted_address;
    // // this.locationDetails.county = (data || {}).locality ;
    // this.locationDetails.zipCode = (data || {}).postal_code || '';

    const value = {
      longitude: data.lng,
      latitude: data.lat
    };
    this.showPosition(value);
    this.showGoogleAutoCompleteMarker(value);
    //this.getAllPolygons();
  }

  showPosition = (position: any): void => {
    const center = [position.longitude, position.latitude];
    this.map.panTo(center);
    this.map.setCenter(center);
    this.map.setZoom(14);
  }

  showGoogleAutoCompleteMarker = (item: any): void => {
    this.map.setCenter([item.longitude, item.latitude]);
    const marker = new mapboxgl.Marker({
      draggable: false
    }).setLngLat([item.longitude, item.latitude]).addTo(this.map);
  }

}
