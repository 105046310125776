import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { HttpClient, HttpParams, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { BaseApiUrl } from '../common/core/baseApiUrl';
import { ResponseStatusCode } from '../common/core/responseStatusCode';
import Swal from 'sweetalert2'

@Injectable({
  providedIn: 'root'
})
export class HttpService {

  constructor(
    private http: HttpClient
  ) { }

  /**  Constructs a new HTTP header object.
  * @return An `Object` of the HttpHeaders.
  */
  private getHeaders = (): HttpHeaders => {
    const accessToken = localStorage.getItem('accessToken');
    const headers = new HttpHeaders({
      'authorization': accessToken ? 'bearer ' + accessToken : '',
      'Content-Type': 'application/json'
    });

    return headers;
  }

  /**  Constructs a new HTTP header object for get data.
  * @return An `Object` of the HttpHeaders.
  */
  private getHeadersGetData = (): HttpHeaders => {
    const accessToken = localStorage.getItem('accessToken');
    const headers = new HttpHeaders({
      'authorization': accessToken ? 'bearer ' + accessToken : '',
      'Content-Type': 'application/x-www-form-urlencoded'
    });

    return headers;
  }

  /**  Constructs a new HTTP header object for form data.
  * @return An `Object` of the HttpHeaders.
  */
  private getHeadersFormData = (): HttpHeaders => {
    const accessToken = localStorage.getItem('accessToken');
    const headers = new HttpHeaders({
      'authorization': accessToken ? 'bearer ' + accessToken : ''
    });

    return headers;
  }

  /** 
  * This method is being called whenever HTTP method throw error.
  * @param (Error of Http Error Response)
  * @return An `Observable` of the throwError.
  */
  private errorHandler(error: HttpErrorResponse) {
    if (error.status == ResponseStatusCode.UNAUTHORIZED) {
      localStorage.removeItem('accessToken');
    }
    Swal.fire({
      title: error.error.message,
      // text: error.error.message,
      icon: 'error',
      confirmButtonText: 'OK',
      customClass: 'uk-animation-slide-top-small',
      confirmButtonColor: '#542581'
    });

    return throwError(error.error);
  }

  /**
* @method (patch http request)
* @param url (api url)
* @param data (data which we have to pass to server directly without formdata and even data is optional)
* 
* @return An `Observable` of the response.
*/
  patchData(url: string, data?: any): Observable<any> {
    const apiUrl = `${BaseApiUrl.URL_USER}${url}`;

    return this.http.patch(apiUrl, data, { headers: this.getHeaders() }).pipe(map((response: any) => {
      return response;
    }), catchError(this.errorHandler));

  }


  /**
   * @method (post http request)
   * @param url (api url)
   * @param data (data which we have to pass to server directly without formdata and data is optional)
   * 
   * @return An `Observable` of the response.
   */
  postData(url: string, data?: any): Observable<any> {
    const apiUrl = `${BaseApiUrl.URL_USER}${url}`;

    return this.http.post(apiUrl, data, { headers: this.getHeaders() }).pipe(map((response: any) => {
      return response;
    }), catchError(this.errorHandler));

  }

  /**
  * @method (post http request)
  * @param url (api url)
  * @param data (data which we have to pass to server with formdata)
  * 
  * @return An `Observable` of the response.
  */
  postDataFormData(url: string, data: any): Observable<any> {
    const apiUrl = `${BaseApiUrl.URL_USER}${url}`;

    return this.http.post(apiUrl, this.appendFormData(data), { headers: this.getHeadersFormData() }).pipe(map((response: any) => {
      return response;
    }), catchError(this.errorHandler));

  }

  /**
   * @method (append formdata)
   * @param myFormData (pass which you want to make formdata type)
   * 
   * @return A `FormData` to be return.
   */
  private appendFormData(myFormData: { [x: string]: any; }): FormData {
    const formData = new FormData();
    for (const key of Object.keys(myFormData)) {
      formData.append(key, myFormData[key]);
    }
    return formData;
  }

  /**
   * @method (get http request)
   * @param url (api url)
   * @param data (all search params and params  is optional)
   * 
   * @return An `Observable` of the response.
   */
  getData(url: string, data?: any): Observable<any> {
    const apiUrl = `${BaseApiUrl.URL_USER}${url}`;

    return this.http.get(apiUrl, { params: this.appendParams(data), headers: this.getHeadersGetData() }).pipe(map((response: any) => {
      return response;
    }), catchError(this.errorHandler));

  }

  /**
   * @method (get http request)
   * @param url (api url)
   * @param data (all search params and params  is optional)
   * 
   * @return An `Observable` of the response.
   */
  getCommonData(url: string, data?: any): Observable<any> {
    const apiUrl = `${BaseApiUrl.URL_COMMON}${url}`;

    return this.http.get(apiUrl, { params: this.appendParams(data), headers: this.getHeadersGetData() }).pipe(map((response: any) => {
      return response;
    }), catchError(this.errorHandler));

  }

  /**
   * @method (append params)
   * @param myParams (pass data which you want to make http params)
   * 
   * @return A `HttpParams` to be return.
   */
  private appendParams(myParams: { [x: string]: any; }): HttpParams {
    let params = new HttpParams();
    if (!!myParams) {
      for (const key of Object.keys(myParams)) {
        params = params.append(key, myParams[key]);
      }
    }
    return params;
  }

  /**
  * @method (delete http request)
  * @param url (api url)
  * 
  * @return An `Observable` of the response.
  */
  deleteData(url: string): Observable<any> {
    const apiUrl = `${BaseApiUrl.URL_USER}${url}`;

    return this.http.delete(apiUrl, { headers: this.getHeaders() }).pipe(map((response: any) => {
      return response;
    }), catchError(this.errorHandler));

  }

  /**
 * @method (put http request)
 * @param url (api url)
 * @param data (data which we have to pass to server directly without formdata and even data is optional)
 * 
 * @return An `Observable` of the response.
 */
  putData(url: string, data?: any): Observable<any> {
    const apiUrl = `${BaseApiUrl.URL_USER}${url}`;

    return this.http.put(apiUrl, data, { headers: this.getHeaders() }).pipe(map((response: any) => {
      return response;
    }), catchError(this.errorHandler));

  }

  /**
   * We have to use this method to call api's for third party .
   * @method (get http request)
   * @param url (api url)
   * 
   * @return An `Observable` of the response.
   */
  getDataByURL(url: string): Observable<any> {
    const apiUrl = `${url}`;

    return this.http.get(apiUrl).pipe(map((response: any) => {
      return response;
    }));

  }

  /**
   * @method (get http request)
   * @param url (api url)
   * @param data (all search params and params  is optional)
   * 
   * @return An `Observable` of the response.
   */
  getDataByURLWithAppendParams(url: string, data?: any): Observable<any> {
    const apiUrl = `${url}`;

    return this.http.get(apiUrl, { params: this.appendParams(data) }).pipe(map((response: any) => {
      return response;
    }));

  }

  /**
  * We have to call this method when we need to call api for CSV data
  * @method (get http request)
  * @param url (api url)
  * @param data (all search params and params  is optional)
  * 
  *  @return An `Observable` of the response.
  */
  getDataCSV(url: string, data?: any): Observable<any> {
    const apiUrl = `${BaseApiUrl.URL_USER}${url}`;

    return this.http.get(apiUrl, { params: this.appendParams(data), headers: this.getHeadersForCSV(), responseType: 'text' }).pipe(map((response: any) => {
      return response;
    }), catchError(this.errorHandler));

  }

  /**
  *  We have to call this method when we need to call api for CSV data
  * @method (post http request)
  * @param url (api url)
  * @param data (data which we have to pass to server directly without formdata and data is optional)
  * 
  * @return An `Observable` of the response.
  */
  postDataCSV(url: string, data?: any): Observable<any> {
    const apiUrl = `${BaseApiUrl.URL_USER}${url}`;

    return this.http.post(apiUrl, data, { headers: this.getHeadersForCSV(), responseType: 'text' }).pipe(map((response: any) => {
      return response;
    }), catchError(this.errorHandler));

  }

  /**  Constructs a new HTTP header object for CSV post and get method.
  * @return An `Object` of the HttpHeaders.
  */
  private getHeadersForCSV = (): HttpHeaders => {
    const accessToken = localStorage.getItem('accessToken');
    const headers = new HttpHeaders({
      'authorization': accessToken ? 'bearer ' + accessToken : '',
      'Content-Type': ''
    });

    return headers;
  }

  getVINDecoderWithAppendParams(data?: any): Observable<any> {
    const apiUrl = `${'https://vin-decoder7.p.rapidapi.com/vin'}`;
    const headers = new HttpHeaders({
      'X-RapidAPI-Key': '8b794202f9msh81fadd4c2246516p1117a5jsn494a52ee36fd',
      'X-RapidAPI-Host': 'vin-decoder7.p.rapidapi.com'
    });

    return this.http.get(apiUrl, { params: this.appendParams(data), headers: headers }).pipe(map((response: any) => {
      return response;
    }));

  }

}
