import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { ResponseStatusCode } from '../common/core/responseStatusCode';

declare let bootstrap: any;

@Injectable({
  providedIn: 'root'
})
export class UtilityService {

  /**  
  * This @Property is used to subscribe child component to re-render its data table.
  */
  refreshDataTable: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  refreshDashboardGraphDetails: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  refreshCustomGraphDetails: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  /**  
 * This @Property is used for creating new Order.
 */
  public newOrderPlaceDetails: { locationDetails: any, vehicleDetails: any[] } =
    { locationDetails: null, vehicleDetails: [] };
  /**  
  * This @Property is used to stored data of graph
  */
  // public dashboardGraphDetails: any = null;

  constructor(
    private router: Router
  ) { }

  /**  
  * This @method is being called whenever HTTP throw error.
  * @param error
  */
  unAuthorized = (error: any): void => {
    if (error.statusCode == ResponseStatusCode.UNAUTHORIZED) {
      this.router.navigate(['/login']);
    }
  }

  /**  
  * This @method is used to show the fuel grade.
  * @param grade fuel grade
  * @return empty string if fuel grade is as number otherwise return grade
  */
  getFuelGrade = (grade: any): any => {
    if (grade) {
      if (/^-?[\d.]+(?:e-?\d+)?$/.test(grade)) {
        return '';
      } else {
        return ' (' + grade + ')';
      }
    } else {
      return '';
    }
  }

  /**  
  * This @method is used to download CSV file.
  * @param response (CSV data).
  * @param name file name of CSV
  */
  downloadCSV = (response: any, name: string): void => {
    const myBlob = new Blob([response], { type: 'text/csv' });
    const downloadUrl = URL.createObjectURL(myBlob);

    const a = document.createElement('a');
    a.href = downloadUrl;
    a.download = `${name}.csv`; // you can take a custom name as well as provide by server

    // start download
    a.click();
    // after certain amount of time remove this object!!!
    setTimeout(() => {
      URL.revokeObjectURL(downloadUrl);
    }, 100);
  }

  /**  
  * This @method is used to download PDF file.
  * @param response (PDF data).
  * @param name file name of PDF
  */
  downloadPDF = (response: any, name: string): void => {
    const myBlob = new Blob([response], { type: 'application/pdf' });
    const downloadUrl = URL.createObjectURL(myBlob);
    const a = document.createElement('a');
    a.href = downloadUrl;
    a.download = `${name}.pdf`; // you can take a custom name as well as provide by server

    // start download
    a.click();
    // after certain amount of time remove this object!!!
    setTimeout(() => {
      URL.revokeObjectURL(downloadUrl);
    }, 100);

  }

  /**  
   * This @method is used to download QR-CODE Image.
   * @param response (IMAGE data).
   * @param name image name of QR-Code
   */
  downLoadQRCodeImage = (response: any, name: string): void => {
    const TYPED_ARRAY: any = new Uint8Array(response.data);
    const STRING_CHAR = String.fromCharCode.apply(null, TYPED_ARRAY);
    const base64String = btoa(STRING_CHAR);
    var a = document.createElement('a');
    a.href = `${'data:image/jpg;base64,'}${base64String}`;
    a.download = `${name}.jpg`;
    a.target = `${'_blank'}`;
    a.click();
  }

  /**  
   * Initialize the bootstrap tooltip from all html element where having @property data-bs-toggle="tooltip".
   */
  initializeToolTip = (): void => {
    // for tooltip
    setTimeout(() => {
      var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
      var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
        return new bootstrap.Tooltip(tooltipTriggerEl)
      });
    }, 2000);
  }

  /**  
  * This @method is used to download CSV file after converting data to CSV.
  * @param data (data to convert into CSV data for download).
  * @param filename file name of CSV
  */
  convertCSV(data: any[], filename: string) {
    const csvData = this.ConvertToCSV(data);
    const a = document.createElement('a');
    a.setAttribute('style', 'display:none;');
    document.body.appendChild(a);
    const blob = new Blob([csvData], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);
    a.href = url;

    // const isIE = /* @cc_on!@ */ false || !!document.documentMode;

    // if (isIE) {
    //   const retVal = navigator.msSaveBlob(blob, `${filename}.csv`);
    // } else {
    //   a.download = `${filename}.csv`;
    // }
    // If you will any error in a.download then dont worry about this.
    a.download = `${filename}.csv`;
    a.click();

  }

  /**  
  * This @method is used to convert data to CSV.
  * @param objArray .
  * @return CSV data after making
  */
  private ConvertToCSV(objArray: any[]) {
    const array = typeof objArray !== 'object' ? JSON.parse(objArray) : objArray;
    let str = '';
    let row = '';

    for (var index in objArray[0]) {
      // Now convert each value to string and comma-separated
      row += `${index},`;
    }
    row = row.slice(0, -1);
    // append Label row with line break
    str += `${row}\r\n`;

    for (let i = 0; i < array.length; i++) {
      let line = '';
      for (var index in array[i]) {
        if (line != '') line += ',';

        line += array[i][index];
      }
      str += `${line}\r\n`;
    }
    return str;
  }

  /**  
  * This @method is used to download CSV file after converting data to CSV with Multi Header of CSV file.
  * @param data (data to convert into CSV data for download).
  * @param filename file name of CSV
  */
  convertCSVMultiHeader(data: any[], filename: string) {
    const csvData = this.ConvertToCSVMultiHeader(data);
    const a = document.createElement('a');
    a.setAttribute('style', 'display:none;');
    document.body.appendChild(a);
    const blob = new Blob([csvData], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);
    a.href = url;

    a.download = `${filename}.csv`;
    a.click();
  }

  /**  
  * This @method is used to convert data to CSV with Multi Header of CSV file.
  * @param objArray .
  * @return CSV data after making
  */
  private ConvertToCSVMultiHeader(objArray: any[]) {
    const array = typeof objArray !== 'object' ? JSON.parse(objArray) : objArray;
    let str = '';
    let row = '';

    for (var index in objArray[0]) {
      // Now convert each value to string and comma-separated
      row += `${index},`;
    }
    row = row.slice(0, -1);
    // append Label row with line break
    str += `${row}\r\n`;

    for (let i = 0; i < array.length; i++) {

      let tempRow = '';
      if (array[i] == '\n') {
        for (var indexFirst in array[i + 1]) {
          tempRow += `${indexFirst},`;
        }
        str += `\r\n${tempRow}\r\n`;
        continue;
      }

      let line = '';
      for (var index in array[i]) {
        if (line != '') line += ',';

        line += array[i][index];
      }
      str += `${line}\r\n`;
    }
    return str;
  }

  /**  
  * This @method is used to get the to fixed value.
  * @param data . need to fixed
  * @param toFixedValue how many to fixed the value
  * @return fixed value as string
  */
  getFixed(data: any, toFixedValue: number) {
    return parseFloat(data).toFixed(toFixedValue);
  }

  /**  
 * This @method is used to get number with commas seprated.
 * @param data . need to be commas seprated
 * @return 
 */
  numberWithCommas = (data: any): any => {
    var parts = data.toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return parts.join(".");
  }

  normalizePhoneNumber = (phone: any): any => {
    phone = phone.replace(/[^\d]/g, "");
    if (phone.length == 10) {
      return phone.replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3");
    }
    return null;
  }

  unNormalizePhoneNumber = (phone: any): any => {
    phone = phone.replace(/[^\d]/g, "");
    if (phone.length == 10) {
      return phone.replace(/(\d{3})(\d{3})(\d{4})/, "$1$2$3");
    }
    return null;
  }

}
