<header class="login-bg ">
    <nav class="overlay-login">
        <div class="container-fluid flex-c">
            <a href="javascript:void(0)" class="logo-position">
                <!-- <img
                    src="./../../../assets/images/login-flow-images/logo-with-title-purple.svg"> -->
                    <i class="">
                        <img src="./../../../assets/images/login-flow-images/Logo-Animation.gif" width="60px" />
                        <img src="./../../../assets/images/login-flow-images/guilogotext.svg" width="140px" class="logotext"/>
                    </i>
                </a>
            <div class="login-cover">
                <h4 class="login-head" style="padding-bottom: 10px !important;">Signup</h4>
                <form [formGroup]="signUpForm" (ngSubmit)="signUp()">

                    <div class="mb-3">
                        <label for="companyName">Business Name</label>
                        <input type="text" class="form-control" id="companyName" placeholder="Enter your Business Name"
                            name="companyName" autocomplete="off" formControlName="companyName">
                        <span class="show-input-error"
                            *ngIf="signUpForm.controls['companyName'].hasError('required') && showError">Please enter
                            your Business Name</span>
                    </div>

                    <div class="mb-3">
                        <label for="email">E-mail</label>
                        <input type="text" class="form-control" id="email" placeholder="Enter your E-mail" name="email"
                            autocomplete="off" formControlName="email">
                        <span class="show-input-error"
                            *ngIf="signUpForm.controls['email'].hasError('required') && showError">Please enter your
                            E-mail</span>
                        <span class="show-input-error" *ngIf="!signUpForm.controls['email'].hasError('required') 
                            && signUpForm.controls['email'].hasError('email') && showError">Please enter valid
                            E-mail</span>
                    </div>

                    <div class="mb-3">
                        <label for="email">Mobile Number</label>
                        <input type="text" class="form-control" id="phone" placeholder="Enter your Mobile number"
                            name="phoneNumber" autocomplete="off" formControlName="phoneNumber">
                        <span class="show-input-error"
                            *ngIf="signUpForm.controls['phoneNumber'].hasError('required') && showError">Please enter
                            your Mobile Number</span>
                    </div>

                    <div class="mb-3">
                        <label for="pwd">Password</label>
                        <div class="position-relative">
                            <input id="password-field" type="{{passwordType}}" class="form-control" name="password"
                                autocomplete="off" formControlName="password" placeholder="Enter your Password">
                            <span toggle="#password-field" class="{{passwordTypeClass}}"
                                (click)="changePasswordType()" style="cursor: pointer;"></span>
                        </div>
                        <span class="show-input-error"
                            *ngIf="signUpForm.controls['password'].hasError('required') && showError">Please enter your
                            Password</span>
                        <span class="show-input-error" *ngIf="!signUpForm.controls['password'].hasError('required') 
                        && signUpForm.controls['password'].hasError('minlength') && showError">Password length must be
                            at least 6 characters long</span>
                    </div>

                    <div class="mb-3">
                        <label for="pwd">Referral Code (Optional)</label>
                        <input type="text" class="form-control" id="pwd" placeholder="Enter your Referral Code "
                            name="referalCode" autocomplete="off" formControlName="referalCode">
                    </div>

                    <button type="submit" class="btn btn-default btn-hover color-1 mb-3 ">Next</button>
                    <p class="acc-create">Already have an account?<a href="javascript:void(0)"
                            [routerLink]="['/login']"> Login</a></p>
                </form>
            </div>
        </div>
    </nav>
</header>