<nav class="navbar navbar-expand-lg navbar-dark fixed-top py-lg-1 py-3" id="mainNav">
    <div class="container-fluid">
        <a class="site-logo d-lg-none align-items-center" href="javascript:void(0)">
            <img src="./../../../assets/images/login-flow-images/Logo-Animation.gif" alt="Site Logo">
            <img src="./../../../assets/images/login-flow-images/guilogotext.svg" alt="Site Logo">
        </a>
        <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon">
                <i class="fa-solid fa-bars"></i>
            </span>
        </button>
    </div>
    <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <div class="sidebar-outer">
            <a class="site-logo position-absolute d-lg-block d-none" href="javascript:void(0)">
                <!-- <img src="./../../../assets/images/login-flow-images/logo-with-title-white.svg" alt="Site Logo"> -->
                <img src="./../../../assets/images/login-flow-images/Logo-Animation.gif" alt="Site Logo" width="50px">
                <img src="./../../../assets/images/login-flow-images/guilogotext.svg" alt="Site Logo" width="140px">
            </a>
            <ul class="navbar-nav navbar-sidenav" id="exampleAccordion">
                <li class="nav-item" data-bs-toggle="tooltip" data-placement="right"
                    [ngClass]="{'active' : sidebarType== 1}">
                    <a class="nav-link" href="javascript:void(0)" (click)="navigate('/dashboard')">
                        <i class="d-icon">
                            <img src="./../../../assets/images/login-flow-images/dashboard.svg" alt="">
                        </i>
                        <span class="nav-link-text">Dashboard</span>
                    </a>
                </li>
                <li class="nav-item" data-bs-toggle="tooltip" data-placement="right"
                    [ngClass]="{'active' : sidebarType== 11}">
                    <a class="nav-link" href="javascript:void(0)" (click)="navigate('/delivery-locations')">
                        <i class="d-icon">
                            <!--location.svg-->
                            <img src="./../../../assets/images/login-flow-images/delivery-location-maker-blue.svg"
                                alt="">
                        </i>
                        <span class="nav-link-text">Delivery Locations</span>
                    </a>
                </li>
                <li class="nav-item" data-bs-toggle="tooltip" data-placement="right"
                    [ngClass]="{'active' : sidebarType== 21}">
                    <a class="nav-link" href="javascript:void(0)" (click)="navigate('/manage-vehicles')">
                        <i class="d-icon">
                            <img src="./../../../assets/images/login-flow-images/car.svg" alt="">
                        </i>
                        <span class="nav-link-text">Manage Vehicles</span>
                    </a>
                </li>
                <!-- <li class="nav-item" data-bs-toggle="tooltip" data-placement="right"
                    [ngClass]="{'active' : sidebarType== 31}">
                    <a class="nav-link" href="javascript:void(0)" (click)="navigate('/orders')">
                        <i class="d-icon">
                            <img src="./../../../assets/images/login-flow-images/petrol.svg" alt="">
                        </i>
                        <span class="nav-link-text">Orders</span>
                    </a>
                </li>
                <li class="nav-item" data-bs-toggle="tooltip" data-placement="right"
                    [ngClass]="{'active' : sidebarType== 41}">
                    <a class="nav-link" href="javascript:void(0)" (click)="navigate('/automatic-orders')">
                        <i class="d-icon">
                            <img src="./../../../assets/images/login-flow-images/loop.svg" alt="">
                        </i>
                        <span class="nav-link-text">Automatic Orders</span>
                    </a>
                </li> -->

                <li class="nav-item" data-placement="right">
                    <a class="nav-link nav-link-collapse" (click)="onClickCollapse('ORDERS')"
                        [ngClass]="{'collapsed': !isShowOrders}">
                        <i class="d-icon">
                            <img src="./../../../assets/images/login-flow-images/orders-dropdown.svg" alt="">
                        </i>
                        <span class="nav-link-text">Orders</span>
                    </a>
                    <ul class="sidenav-second-level collapse" [ngClass]="{'show': isShowOrders}">
                        <li [ngClass]="{'active' : sidebarType== 31}">
                            <a class="nav-link" href="javascript:void(0)" (click)="navigate('/orders')">
                                <i class="d-icon">
                                    <img src="./../../../assets/images/login-flow-images/petrol.svg" alt="">
                                </i>
                                <span class="nav-link-text show-reports">Order Activity</span>
                            </a>
                        </li>
                        <li [ngClass]="{'active' : sidebarType== 41}">
                            <a class="nav-link" href="javascript:void(0)" (click)="navigate('/automatic-orders')">
                                <i class="d-icon">
                                    <img src="./../../../assets/images/login-flow-images/loop.svg" alt="">
                                </i>
                                <span class="nav-link-text show-reports">Automatic Orders</span>
                            </a>
                        </li>
                    </ul>
                </li>


                <li class="nav-item" data-bs-toggle="tooltip" data-placement="right"
                    [ngClass]="{'active' : sidebarType== 51}">
                    <a class="nav-link" href="javascript:void(0)" (click)="navigate('/payments')">
                        <i class="d-icon">
                            <img src="./../../../assets/images/login-flow-images/payment.svg" alt="">
                        </i>
                        <span class="nav-link-text">Payments</span>
                    </a>
                </li>

                <!-- <li class="nav-item" data-bs-toggle="tooltip" data-placement="right"
                    [ngClass]="{'active' : sidebarType== 111}">
                    <a class="nav-link" href="javascript:void(0)" (click)="navigate('/wallet')">
                        <i class="d-icon">
                            <img src="./../../../assets/images/login-flow-images/payment.svg" alt="">
                        </i>
                        <span class="nav-link-text">Wallet</span>
                    </a>
                </li> -->

                <!-- <li class="nav-item" data-bs-toggle="tooltip" data-placement="right">
                    <a class="nav-link nav-link-collapse collapsed" data-bs-toggle="collapse" href="#collapseMulti"
                        data-parent="#exampleAccordion">
                        <i class="d-icon">
                            <img src="./../../../assets/images/login-flow-images/payment.svg" alt="">
                        </i>
                        <span class="nav-link-text">Payments</span>
                    </a>
                    <ul class="sidenav-second-level collapse" id="collapseMulti">
                        <li>
                            <a href="javascript:void(0)">Second Level Item</a>
                        </li>
                        <li>
                            <a href="javascript:void(0)">Second Level Item</a>
                        </li>
                        <li>
                            <a href="javascript:void(0)">Second Level Item</a>
                        </li>
                        <li>
                            <a class="nav-link-collapse collapsed" data-bs-toggle="collapse"
                                href="#collapseMulti2">Reports</a>
                            <ul class="sidenav-third-level collapse" id="collapseMulti2">
                                <li>
                                    <a href="javascript:void(0)">Third Level Item</a>
                                </li>
                                <li>
                                    <a href="javascript:void(0)">Third Level Item</a>
                                </li>
                                <li>
                                    <a href="javascript:void(0)">Third Level Item</a>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </li> -->
                <!-- <li class="nav-item" data-bs-toggle="tooltip" data-placement="right"
                    [ngClass]="{'active' : sidebarType== 61}">
                    <a class="nav-link" href="javascript:void(0)" (click)="navigate('/reports')">
                        <i class="d-icon">
                            <img src="./../../../assets/images/login-flow-images/edit.svg" alt="">
                        </i>
                        <span class="nav-link-text">Reports</span>
                    </a>
                </li>

                <li class="nav-item" data-bs-toggle="tooltip" data-placement="right"
                    [ngClass]="{'active' : sidebarType == 71}">
                    <a class="nav-link" href="javascript:void(0)" (click)="navigate('/customer-reports')">
                        <i class="d-icon">
                            <img src="./../../../assets/images/login-flow-images/edit.svg" alt="">
                        </i>
                        <span class="nav-link-text">Customer Reports</span>
                    </a>
                </li>

                <li class="nav-item" data-bs-toggle="tooltip" data-placement="right"
                    [ngClass]="{'active' : sidebarType== 91}">
                    <a class="nav-link" href="javascript:void(0)" (click)="navigate('/custom-reports')">
                        <i class="d-icon">
                            <img src="./../../../assets/images/login-flow-images/edit.svg" alt="">
                        </i>
                        <span class="nav-link-text">Custom Reports</span>
                    </a>
                </li> -->

                <li class="nav-item" data-placement="right">
                    <!--data-bs-toggle="collapse"
                        data-bs-parent="#exampleAccordion" data-bs-target="#collapseMultiReports"-->
                    <a class="nav-link nav-link-collapse" (click)="onClickCollapse('REPORTS')"
                        [ngClass]="{'collapsed': !isShowReports}">
                        <i class="d-icon">
                            <img src="./../../../assets/images/login-flow-images/edit.svg" alt="">
                        </i>
                        <span class="nav-link-text">Reports</span>
                    </a>
                    <!--id="collapseMultiReports"-->
                    <ul class="sidenav-second-level collapse" [ngClass]="{'show': isShowReports}">
                        <li [ngClass]="{'active' : sidebarType== 61}">
                            <a class="nav-link" href="javascript:void(0)" (click)="navigate('/snapshot-reports')">
                                <i class="d-icon">
                                    <img src="./../../../assets/images/login-flow-images/edit.svg" alt="">
                                </i>
                                <span class="nav-link-text show-reports">Snapshot Reports</span>
                            </a>
                        </li>
                        <li [ngClass]="{'active' : sidebarType== 71}">
                            <a class="nav-link" href="javascript:void(0)" (click)="navigate('/detailed-reports')">
                                <i class="d-icon">
                                    <img src="./../../../assets/images/login-flow-images/edit.svg" alt="">
                                </i>
                                <span class="nav-link-text show-reports">Detailed Reports</span>
                            </a>
                        </li>
                        <li [ngClass]="{'active' : sidebarType== 91}">
                            <a class="nav-link" href="javascript:void(0)" (click)="navigate('/custom-reports')">
                                <i class="d-icon">
                                    <img src="./../../../assets/images/login-flow-images/edit.svg" alt="">
                                </i>
                                <span class="nav-link-text show-reports">Custom Reports</span>
                            </a>
                        </li>
                    </ul>
                </li>

                <li class="nav-item" data-bs-toggle="tooltip" data-placement="right"
                    [ngClass]="{'active' : sidebarType== 101}">
                    <a class="nav-link" href="javascript:void(0)" (click)="navigate('/telematics')">
                        <i class="d-icon">
                            <img src="./../../../assets/images/login-flow-images/telematics-icon.svg" alt="">
                        </i>
                        <span class="nav-link-text">Telematics</span>
                    </a>
                </li>

                <!-- <li class="nav-item" data-bs-toggle="tooltip" data-placement="right"
                [ngClass]="{'active' : sidebarType== 71}">
                    <a class="nav-link nav-link-collapse collapsed" data-bs-toggle="collapse" href="#collapseMulti"
                        data-parent="#exampleAccordion">
                        <i class="d-icon">
                            <img src="./../../../assets/images/login-flow-images/edit.svg" alt="">
                        </i>
                        <span class="nav-link-text">Customer Reports</span>
                    </a>
                    <ul class="sidenav-second-level collapse" id="collapseMulti">
                        <li>
                            <a href="javascript:void(0)" (click)="navigate('/customer-reports/gallons-per-fillup')">>>
                                Gallons (per fillup)</a>
                        </li>
                        <li>
                            <a href="javascript:void(0)" (click)="navigate('/customer-reports/ppg-per-fillup')">>> PPG
                                (per fillup)</a>
                        </li>
                        <li>
                            <a href="javascript:void(0)" (click)="navigate('/customer-reports/report-detail')">>> Report
                                Detail</a>
                        </li>
                        <li>
                            <a href="javascript:void(0)" (click)="navigate('/customer-reports/total-by-booking-no')">>>
                                Total By Booking No</a>
                        </li>
                        <li>
                            <a href="javascript:void(0)"
                                (click)="navigate('/customer-reports/total-by-customer-vehicle')">>> Total By Customer
                                Vehicle</a>
                        </li>
                        <li>
                            <a href="javascript:void(0)"
                                (click)="navigate('/customer-reports/output-gal-by-vehicle')">>> Output Gal By
                                Vehicle</a>
                        </li>
                        <li>
                            <a href="javascript:void(0)" (click)="navigate('/customer-reports/report-by-vehicle')">>>
                                Report By Vehicle</a>
                        </li>
                    </ul>
                </li> -->

                <li class="nav-item" data-bs-toggle="tooltip" data-placement="right">
                    <a class="nav-link" href="javascript:void(0)" (click)="resetPassword()">
                        <i class="d-icon">
                            <img src="./../../../assets/images/login-flow-images/unlock.svg" alt="">
                        </i>
                        <span class="nav-link-text">Reset Password</span>
                    </a>
                </li>
                <li class="nav-item" data-bs-toggle="tooltip" data-placement="right"
                    [ngClass]="{'active' : sidebarType== 81}">
                    <a class="nav-link" href="javascript:void(0)" (click)="navigate('/profile')">
                        <i class="d-icon">
                            <img src="./../../../assets/images/login-flow-images/admin.svg" alt="">
                        </i>
                        <span class="nav-link-text">Profile</span>
                    </a>
                </li>
                <li class="nav-item" data-bs-toggle="tooltip" data-placement="right">
                    <a class="nav-link" href="javascript:void(0)" (click)="signOut()">
                        <i class="d-icon">
                            <img src="./../../../assets/images/login-flow-images/ic_logout.svg" alt="">
                        </i>
                        <span class="nav-link-text">Sign Out</span>
                    </a>
                </li>
            </ul>


        </div>
    </div>
</nav>